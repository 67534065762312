import { baseUrl, authUrl } from '../config/env'
import axios from './axios'
import { encryption } from './encryption'
import Cookies from 'js-cookie'
let getAuthHeaders = () => {
  return {
    'Authorization': 'Basic ZG9tZWQ6ZG9tZWRhdXRo',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
let getAccessHeaders = () => {
  var token = Cookies.get('token')
  var headers = {}
  if (token != undefined && token != '' && token != 'undefined') {
    headers.Authorization = 'Bearer ' + token
    // headers['Content-Type'] = 'application/json'
  }
  headers['Content-Type'] = 'application/json'
  return headers
}
let getAccessHeaderd = () => {
  var token = Cookies.get('token')
  var headers = {}
  if (token != undefined && token != '' && token != 'undefined') {
    //headers.Authorization = 'Bearer ' + token
    // headers['Content-Type'] = 'application/json'
  }
  headers['Content-Type'] = 'multipart/form-data'
  return headers
}
let addImgUploadHeaders = () => {
  var token = Cookies.get('token')
  let headers = {}
  if (token != undefined && token != '' && token != 'undefined') {
    headers.Authorization = 'Bearer ' + token
  }
  // headers['Content-Type'] = 'multipart/form-data'
  headers['Access-Control-Allow-Origin'] = '*'
  return headers
}
// let UrlFactory = {
//   //测试
//   test: function () { return baseUrl + '/test/test.json' },
//
// }
let UrlFactory = {
  //测试
  test: function () { return baseUrl },
  // ----------------------------------------------------韦愉超 start-------------------------------------------------
  //推荐有礼页面接口
  userIntegralInfo: function () { return baseUrl +'/etc/GiftRecommendedInit.json'},
  // ----------------------------------------------------韦愉超 end---------------------------------------------------
  // ----------------------------------------------------任英杰 start-------------------------------------------------
  //预约列表接口
  querySeeWithList: function () { return baseUrl +'/person/querySeeWithList.json'},
  //租客取消预约接口
  rentCancelSeeWith: function () { return baseUrl +'/person/rentCancelSeeWith.json'},
  //维修项目初始化接口
  userRepairInit: function () { return baseUrl +'/myHome/userRepairInit.json'},
  //字典表接口
  getQueryBaseData: function () { return baseUrl +'/manageCommon/queryBaseData.json'},
  //用户租后取消申请
  userAfterRentCancel: function () { return baseUrl +'/myHome/userAfterRentCancel.json'},
  //我的保洁列表
  querycleaningList: function () { return baseUrl +'/person/querycleaningList.json'},
  //添加投诉接口
  userAddComplaint: function () { return baseUrl +'/myHome/userAddComplaint.json'},
  //租客投诉列表初始化接口
  userComplaintList: function () { return baseUrl +'/myHome/userComplaintList.json'},
  //租客投诉列表取消接口
  userCancelComplaint: function () { return baseUrl +'/myHome/userCancelComplaint.json'},
  //维修保洁初始化接口
  userApplyInit: function () { return baseUrl +'/myHome/userRepairApplyInit.json'},
  //维修申请接口
  userCleanApplySave: function () { return baseUrl +'/myHome/userCleanApplySave.json'},
  //预约合同初始化接口
  findPerContractByRoomId: function () { return baseUrl +'/applet/percontract/findPerContractByRoomId.json'},
  //预约合同添加接口
  percontractsaveAndUpdatePerContractList: function () { return baseUrl +'/applet/percontract/saveAndUpdatePerContractList.json'},
  //预约合同添加接口
  userRepairApplySave: function () { return baseUrl +'/myHome/userRepairApplySave.json'},
  //图片上传接口
  uploadImage: function () { return baseUrl +'/photo/uploadImage.json'},
  //预约合同列表查询
  percontractqueyPerContractList: function () { return baseUrl +'/applet/percontract/queyPerContractList.json'},
  //预约带看选择房源接口
  seeWithRoomList: function () { return baseUrl +'/workbench/seeWithRoomList.json'},
  //带看申请
  addUserAppointment: function () { return baseUrl +'/workbench/addUserAppointment.json'},
  // 带看初始化接口
  seeWithInit: function () { return baseUrl +'/workbench/seeWithInit.json'},
  //获取密码锁接口
  seeWithGetPassword: function () { return baseUrl +'/workbench/seeWithGetPassword.json'},
  //  确认带看录入接口
  seeWithComfirm: function () { return baseUrl +'/workbench/seeWithComfirm.json'},
//房源地址初始化接口
  userRepairAddressList: function () { return baseUrl +'/myHome/userRepairAddressList.json'},
  //租后配货
  afterRentDistributionList: function () { return baseUrl +'/workbench/afterRentDistributionList.json'},
  //配货派单初始化接口
  saveDistributionOrderInit: function () { return baseUrl +'/workbench/saveDistributionOrderInit.json'},
  //配货派单初始化接口
  distributionDetailItemInit: function () { return baseUrl +'/workbench/distributionDetailItemInit.json'},
  //配货派单保存接口
  saveDistributionOrder: function () { return baseUrl +'/workbench/saveDistributionOrder.json'},
  //配货取消接口
  cancelDistribution: function () { return baseUrl +'/workbench/cancelDistribution.json'},
  //配货验收初始化接口
  distributionDetailDispatchInit: function () { return baseUrl +'/workbench/distributionDetailDispatchInit.json'},
  //配货验收保存接口
  saveDistributionAcceptance: function () { return baseUrl +'/workbench/saveDistributionAcceptance.json'},
   //配货派单初始化接口
    changeDistributionInit: function () { return baseUrl +'/workbench/changeDistributionInit.json'},
    //配货派单保存接口
    saveChangeDistribution: function () { return baseUrl +'/workbench/saveChangeDistribution.json'},
  //配货完成初始化接口
  deliveryCompletedHeadList: function () { return baseUrl +'/workbench/deliveryCompletedHeadList.json'},
  //配货完成保存接口
  saveFinishCompleted: function () { return baseUrl +'/workbench/saveFinishCompleted.json'},
  //配货申请详情接口
  distributionDetailInitList: function () { return baseUrl +'/workbench/distributionDetailInitList.json'},
  //配货完成详情接口
  distributionDetailFinishInit: function () { return baseUrl +'/workbench/distributionDetailFinishInit.json'},
  //配货验收详情接口
  queryDistributionApprovalHistory: function () { return baseUrl +'/workbench/queryDistributionApprovalHistory.json'},
  //查询房间
  getPerRoomHouseList: function () { return baseUrl +'/applet/percontract/getPerRoomHouseList.json'},
  //新增预约初始化接口
  getRoomHouseListById: function () { return baseUrl +'/applet/percontract/getRoomHouseListById.json'},
  //查询用户接口
  getUserList: function () { return baseUrl +'/applet/manageCommon/getUserList.json'},


// ----------------------------------------------------任英杰 end---------------------------------------------------

  //查询清退列表
  afterRentClean: function () { return baseUrl +'/afterRentClean/afterRentContRetreatAppList.json'},
  //添加清退图片
  savaUpPhone: function () { return baseUrl +'/afterRentClean/savaUpPhone.json'},
  //清退验收
  acceptanceCheck: function () { return baseUrl +'/afterRentClean/upAfterRentContRetreat.json'},
  // ----------------------------------------------------洪森 start-------------------------------------------------
  //收房申请审核列表接口
  reviewOfApplyForHouseList: function () { return baseUrl +'/workbench/colllectRoomList.json'},
  //潜房详情页面接口
  queryPotentialHouse: function () { return baseUrl +'/workbench/queryPotentialHouse.json'},
  //收房申请详情接口
  colllectRoomDetail: function () { return baseUrl +'/workbench/colllectRoomDetail.json'},
  //收房申请同意接口
  collectRoomAgree: function () { return baseUrl +'/workbench/collectRoomAgree.json'},
  //收房申请拒绝接口
  collectRoomRefuse : function () { return baseUrl +'/workbench/collectRoomRefuse.json'},
  //装修申请负责人列表，设计师列表，费用承担方列表初始化接口
  returnChargeAndDesignerList : function () { return baseUrl +'/afterRentDecoration/queryUserRoleList.json'},
  //业主合同详情初始化
  findOwnerContractInfo: function () { return baseUrl +'/contract/findOwnerContractInfo.json'},
  //合同下的物业交割单
  findContractproperty: function () { return baseUrl +'/applet/rentercontract/findContractproperty.json'},
  //物业交割单初始化查询水表号等
  getMeterNumberAndMore: function () { return baseUrl +'/applet/rentercontract/getMeterNumberAndMore.json'},
  //业主合同账单信息
  queryOwnerContractBill: function () { return baseUrl +'/contract/ownerContractBill.json'},
  //租客合同账单信息
  queryRenterContractBill: function () { return baseUrl +'/applet/rentercontract/renterContractBill.json'},
  //业主合同退租信息
  findContractHistoryDetail: function () { return baseUrl +'/contract/findContractHistoryDetail.json'},
  //业主合同审批信息
  findApprovalHistory: function () { return baseUrl +'/applet/rentercontract/findApprovalHistory.json'},
  //电子合同查看
  findElectronicContractUrl: function () { return baseUrl +'/applet/rentercontract/findElectronicContractUrl.json'},
  //租约变更审核列表初始化
  findCancelContractApprovalList: function () { return baseUrl +'/workbench/changeContractListInit.json'},
  //审核详情初始化
  renterRetreatDetails : function () { return baseUrl +'/workbench/renterRetreatDetails.json'},
  //租约变更申请同意接口
  chanageContractAgree : function () { return baseUrl +'/workbench/chanageContractAgree.json'},
  //租约变更申请拒绝接口
  chanageContractRefuse : function () { return baseUrl +'/workbench/chanageContractRefuse.json'},
  //业主合同列表签约接口
  ownerContractSign : function () { return baseUrl +'/applet/ownercontract/ownerContractSign.json'},
  //业主合同作废功能  取消预约接口
  contractInvalid : function () { return baseUrl +'/applet/ownercontract/contractInvalid.json'},
  //业主或者租客解除合同详情初始化
  approvalContractDetail : function () { return baseUrl +'/contract/approvalContractDetail.json'},
  //租客合同新增或者修改
  saveOrUpdateRenterContract : function () { return baseUrl +'/contract/saveOrUpdateRenterContract.json'},
  //查询员工对应的潜客
  queryStaffPotentialUser: function () { return baseUrl +'/contract/queryStaffPotentialUser.json'},
  //租客合同房源列表
  getRenterRoomHouseList : function () { return baseUrl +'/contract/getRenterRoomHouseList.json'},
  //租客合同中根据房间id查询部分房间信息
  findRentByRoomId : function () { return baseUrl +'/contract/findRentByRoomId.json'},
  //租客合同中根据房间id查询对应的业主合同的截止时间
  querygOwnerContractEndDate : function () { return baseUrl +'/contract/querygOwnerContractEndDate.json'},
  //租客合同中根据房间id查询对应的业主合同的截止时间
  queryRenterFeePolicyDecision : function () { return baseUrl +'/contract/queryRenterFeePolicyDecision.json'},
  //查询租客合同详情
  renterContractInfo : function () { return baseUrl +'/contract/renterContractInfo.json'},
  //查询剩余押金
  queryRestDepositMoneyBill: function () { return baseUrl + '/contract/queryRestDepositMoneyBill.json' },
  //租客合同列表签字功能
  renterContractSign : function () { return baseUrl +'/applet/rentercontract/renterContractSign.json'},
  //根据电话号查询租客列表
  remoteMobileSearch : function () { return baseUrl +'/contract/getUserList.json'},
  //根据房间id查询优惠券
  getCouponList : function () { return baseUrl +'/contract/getCouponList.json'},
  //根据潜客资源id查询潜客对应的预定合同
  queryPotentialUserRequireRoomHouseId : function () { return baseUrl +'/contract/queryPotentialUserRequireRoomHouseId.json'},
  // --------------------------------------------------洪森 end----------------------------------------------------
// ----------------------------------------------------李大力 start-------------------------------------------------
  // 新增或修改合同
  ownercontractsaveOrUpdateOwnercontract: function () { return baseUrl +'/applet/ownercontract/saveOrUpdateOwnercontract.json'},
  // 创建合同编号
  ownercontractcreateContractCode: function () { return baseUrl +'/applet/ownercontract/createContractCode.json'},
  // 查找部门
  findDepartmentByStaffId: function () { return baseUrl +'/applet/ownercontract/findDepartmentByStaffId.json'},
  // 查询推荐人
  queryrecommendUser: function () { return baseUrl +'/globaluser/queryrecommendUser.json'},
  // 根据手机号查询用户信息(包含实名认证)
  findUserInfoByMobile: function () { return baseUrl +'/applet/ownercontract/findUserInfoByMobile.json'},
  // 合同详情
  ownercontractfindOwnerContractInfo: function () { return baseUrl +'/applet/ownercontract/findOwnerContractInfo.json'},
  // 房源收藏列表接口
  roomCollectionList: function () { return baseUrl +'/person/roomCollectionList.json'},
  // 城市列表
  queryCityList: function () { return baseUrl +'/photo/queryCityList.json'},
  // 潜客列表
  potentialUserList: function () { return baseUrl +'/potentialUser/potentialUserList.json'},
  // 配货申请初始化
  distributionListInit: function () { return baseUrl +'/workbench/distributionListInit.json'},
  // 潜客详情 修改潜客初始化
  queryPotentialUser: function () { return baseUrl +'/potentialUser/queryPotentialUser.json'},
  // 带看历史
  seeWithList: function () { return baseUrl +'/workbench/seeWithList.json'},
  // 带看列表
  // browseSeeWith: function () { return baseUrl +'/workbench/browseSeeWith.json'},
// 配货申请详情初始化
  queryAfterRentDistribution: function () { return baseUrl +'/workbench/queryAfterRentDistributionDetails.json'},
  // 配货审核同意接口
  distributionAgree: function () { return baseUrl +'/workbench/distributionAgree.json'},
  // 配货审核拒绝接口
  distributionRefuse: function () { return baseUrl +'/workbench/distributionRefuse.json'},
  // 新增潜客接口
  addOrUpdatePotentialUser: function () { return baseUrl +'/potentialUser/addOrUpdatePotentialUser.json'},
  // 商圈列表(不带城市)
  // businessCircleList: function () { return baseUrl +'/homePage/businessCircleList.json'},
  // 商圈列表分级(城市级联动)
  queryBusinessCircle: function () { return baseUrl +'/manageCommon/queryBusinessCircle.json'},
  // 绑定手机接口
  bindMobile: function () { return baseUrl +'/login/bindMobile.json'},
  // 我的业绩初始化
  browseStaffAchievementList: function () { return baseUrl +'/achievement/achievementList.json'},
    // 我的业绩初始化
    queryStaffAchievementCount: function () { return baseUrl +'/achievement/queryStaffAchievementCount.json'},

  // 业绩详情初始化
  achievementDetail: function () { return baseUrl +'/achievement/achievementDetail.json'},


  // --------------------------------------------------李大力 end----------------------------------------------------
  //------------------------------------------------ 贾萌 start-----------------------------------------------------
  //推荐简历初始化接口
  RecommendResumeInit: function () { return baseUrl+'/etc/RecommendResumeInit.json'},
  //推荐简历接口保存
  RecommendResumeSave: function () { return baseUrl+'/etc/RecommendResumeSave.json'},
  //实勘初始化
  queryExploration: function () { return baseUrl+'/workbench/queryExploration.json'},
  //实勘保存接口
  explorationSave: function () { return baseUrl+'/workbench/explorationSave.json'},
  //添加潜房接口
  addOrUpdatePotentialHouse: function () { return baseUrl+'/workbench/addOrUpdatePotentialHouse.json'},
  //楼盘列表接口
  queryEstateList: function () { return baseUrl+'/workbench/queryEstateList.json'},
  //栋座列表接口
  queryEstatePedestalList: function () { return baseUrl+'/workbench/queryEstatePedestalList.json'},
  //单元列表接口
  queryEstateUnitList: function () { return baseUrl+'/workbench/queryEstateUnitList.json'},
  //房屋列表接口
  queryEstateRoomDetailList: function () { return baseUrl+'/workbench/queryEstateRoomDetailList.json'},
  //收房申请接口
  addcolllectRoom: function () { return baseUrl+'/workbench/addcolllectRoom.json'},
  //实名认证初始化接口
  queryNameCertification: function () { return baseUrl+'/person/queryNameCertification.json'},
  //实名认证接口
  nameCertification: function () { return baseUrl+'/person/nameCertification.json'},
  //保存用户身份证图片
  saveUserPhoto: function () { return baseUrl+'/person/saveUserPhoto.json'},
  //图片base64上传接口
  base64Upload: function () { return baseUrl+'/photo/base64Upload.json'},
  //添加潜房推荐人列表
  seeWithUserList: function () { return baseUrl+'/workbench/seeWithUserList.json'},
  //添加潜客推荐人列表（新）
  findRecommendUser: function () { return baseUrl+'/workbench/findRecommendUser.json'},
  //装修查看详情
  queryRenovation: function () { return baseUrl+'/afterRentDecoration/queryRenovation.json'},
  //装修查看审批记录
  afterrentHisotry: function () { return baseUrl+'/afterRentDecoration/afterrentHisotry.json'},
  //装修查看账单
  browseBillList: function () { return baseUrl+'/afterRentDecoration/browseBillList.json'},
  //添加合同物业交割单
  addContractproperty: function () { return baseUrl+'/applet/rentercontract/addContractproperty.json'},
  //查询物品分类list
  queryAllocationClass: function () { return baseUrl+'/contract/queryAllocationClass.json'},
  //查询物品list
  queryAllocation: function () { return baseUrl+'/contract/queryAllocation.json'},
  //装修详情施工方案详情
  decorationConstructionDetails: function () { return baseUrl+'/afterRentDecoration/decorationConstructionDetails.json'},
  //查询用户身份证照片接口
  queryIdPhoto: function () { return baseUrl+'/person/queryIdPhoto.json'},
  //装修保存改派接口
  saveReformSchool: function () { return baseUrl+'/afterRentDecoration/saveReformSchool.json'},
  //装修初始化改派接口
  showReformSchool: function () { return baseUrl+'/afterRentDecoration/showReformSchool.json'},
  // 删除装修
  delDecoration: function () { return baseUrl+'/afterRentDecoration/delDecoration.json'},
  // 取消装修
  cancelDecoration: function () { return baseUrl+'/afterRentDecoration/cancelDecoration.json'},
  //  装修派单初始化
  decoratListInit: function () { return baseUrl+'/afterRentDecoration/decorationListInit.json'},
  //  装修派单保存
  saveDecorationList: function () { return baseUrl+'/afterRentDecoration/saveDecorationList.json'},
  //   装配方案初始化
  showProgramme: function () { return baseUrl+'/afterRentDecoration/showProgrammeNew.json'},
  //   保存装配方案
  updateRenovationProgramme: function () { return baseUrl+'/afterRentDecoration/updateRenovationProgrammeNew.json'},
  //   保存装修质量验收
  saveAcceptance: function () { return baseUrl+'/afterRentDecoration/saveAcceptance.json'},
  //   已申请金额总
  userCanApplyMoneyInfo: function () { return baseUrl+'/person/userCanApplyMoneyInfo.json'},
  //   租后投诉列表初始化
  afterRentComplaintList: function () { return baseUrl+'/workbench/afterRentComplaintList.json'},
//   租后投诉详情初始化
  afterRentComplaintAcceptInit: function () { return baseUrl+'/workbench/afterRentComplaintAcceptInit.json'},
//   租后投诉历史初始化
  queryComplaintHistory: function () { return baseUrl+'/workbench/queryComplaintHistory.json'},
//   租后删除投诉记录
  removeAfterRentComplaint: function () { return baseUrl+'/workbench/removeAfterRentComplaint.json'},
  //   租后保存投诉受理
  saveAfterRentComplaintAccept: function () { return baseUrl+'/workbench/saveAfterRentComplaintAccept.json'},
  //   租后完成投诉
  updateAfterRentComplaint: function () { return baseUrl+'/workbench/updateAfterRentComplaint.json'},
//   租后投诉核验
  updateAfterRentComplaintCheck: function () { return baseUrl+'/workbench/updateAfterRentComplaintCheck.json'},
//   租后投诉找房源
  complaintApplicationListInit: function () { return baseUrl+'/workbench/complaintApplicationListInit.json'},
//   租后投诉找部门
  queryDepartment: function () { return baseUrl+'/workbench/queryDepartment.json'},
//   智能设备列表
  intelligentDeviceList: function () { return baseUrl+'/intelligentDevice/intelligentDeviceList.json'},

    //更新房间密码
    updateRoomEquipmentPassword: function () { return baseUrl+'/intelligentDevice/updateRoomEquipmentPassword.json'},
    //获取用户密码
    getUserPassword: function () { return baseUrl+'/myHome/getUserPassword.json'},
    //获取用户密码
    getUserPowerInfo: function () { return baseUrl+'/myHome/getUserPowerInfo.json'},

  // ------------------------------------------ 统计 基础人事 begin ----------------------------------
  //中后台占比
  backstageData: function () { return baseUrl +'/basicPerson/recruitBackstage.json'},
  //职务级别占比
  inJobData: function () { return baseUrl +'/basicPerson/recruitLevel.json'},
  //入职渠道
  entryChannelsData: function () { return baseUrl +'/basicPerson/searchRecruitWay.json'},
  //人员流动情况（离职量、入职量）
  personnelTurnoverData: function () { return baseUrl +'/basicPerson/inEmployees.json'},
  //在职时间分析
  workingHoursData: function () { return baseUrl +'/basicPerson/recruitEntryTime.json'},
  //员工年龄
  staffAgeData: function () { return baseUrl +'/basicPerson/recruitEmployeeAge.json'},
  //员工性别
  staffSexData: function () { return baseUrl +'/basicPerson/recruitEmployeeSex.json'},
  //员工学历
  educationData: function () { return baseUrl +'/basicPerson/recruitEmployeeEducation.json'},
  //离职人员入职时长占比
  staffLeavingData: function () { return baseUrl +'/basicPerson/recruitLeavingEmployee.json'},
  // ------------------------------------------ 统计 基础人事 end ------------------------------------
  
  // ------------------------------------------ 统计 租后统计 begin ----------------------------------
  //维修类型占比
  maintainGenre: function () { return baseUrl +'/afterRentStatistics/maintainGenre.json'},
  //维修完成时长
  repairStatistics: function () { return baseUrl +'/afterRentStatistics/repairStatistics.json'},
  //保洁响应时长
  cleanResponseTime: function () { return baseUrl +'/afterRentStatistics/cleanResponseTime.json'},
  //保洁完成时长
  cleanStatistics: function () { return baseUrl +'/afterRentStatistics/cleanStatistics.json'},
  //配货响应时长
  distributionResponseTime: function () { return baseUrl +'/afterRentStatistics/distributionResponseTime.json'},
  //配货分类占比
  distributionStatistics: function () { return baseUrl +'/afterRentStatistics/distributionStatistics.json'},
  //配货完成时长
  distributionFinishTime: function () { return baseUrl +'/afterRentStatistics/distributionFinishTime.json'},
 // ------------------------------------------ 统计 租后统计 end ----------------------------------

  // ------------------------------------------ 统计 业务统计 start ----------------------------------
  //房屋状态占比
  houseStateProportion: function () { return baseUrl +'/businessData/houseStateProportion.json'},
  //空置天数占比
  vacanyDaysNumProportion: function () { return baseUrl +'/businessData/vacanyDaysNumProportion.json'},
  //利差
  homeForeclosure: function () { return baseUrl +'/businessData/homeForeclosure.json'},
  //出房周期
  outHousePeriod: function () { return baseUrl +'/businessData/outHousePeriod.json'},
  //月收房、出房排行榜
  interestMargin: function () { return baseUrl +'/businessData/interestMargin.json'},
  //今、周、月签约量
  signNum: function () { return baseUrl +'/businessData/signNum.json'},
  //签约情况（签约量）
  signCount: function () { return baseUrl +'/businessData/signCount.json'},
  //签约情况（违约量）
  breakContractNum: function () { return baseUrl +'/businessData/breakContractNum.json'},
  //签约情况（续签量）
  renewalNum: function () { return baseUrl +'/businessData/renewalNum.json'},
  //租客租期时长占比
  rentTimeProportion: function () { return baseUrl +'/businessData/rentTimeProportion.json'},
  //业务租客付款方式占比
  businessUserPaymantType: function () { return baseUrl +'/businessData/userPaymantType.json'},
  //房间总量
  totalHouseNum: function () { return baseUrl +'/businessData/totalHouseNum.json'},
  // ------------------------------------------ 统计 业务统计 end ------------------------------------

  //查询一级公司，用于统计公司部门检索
  queryFirstLevelCompany: function () { return baseUrl +'/manageCommon/queryFirstLevelCompany.json'},
  //查询逾期费用
  TotalMoney: function () { return baseUrl +'/financial/TotalMoney.json'},  
  //查询一级公司，用于统计公司部门检索
  findDepartmentByCompanyId: function () { return baseUrl +'/manageCommon/findDepartmentByCompanyId.json'},
  //查询所有部门(带权限) 2021.06.23 王千赫
  queryAuthLevelDepartment: function () { return baseUrl +'/manageCommon/queryAuthLevelDepartment.json'},  

  // ------------------------------------------ 统计 财务统计 begin ----------------------------------
  //房屋账单
  housesBill: function () { return baseUrl +'/financial/housesBill.json'},
  //装修回款
  renovationFund: function () { return baseUrl +'/financial/renovationFund.json'},
  //应收，应支
  receivablePay: function () { return baseUrl +'/financial/receivablePay.json'},
  //日应收，应支
  receivablePayDay: function () { return baseUrl +'/financial/receivablePayDay.json'},
  //租后费用
  afterRent: function () { return baseUrl +'/financial/afterRent.json'},


  // ------------------------------------------ 统计 财务统计 end ------------------------------------


  // ------------------------------------------ 房源相关 start ----------------------------------
  //转租状态切换
  changeHouseType: function () { return baseUrl +'/homePage/changeHouseType.json'},
  //报价底价、房源状态修改
  updataHousePrice: function () { return baseUrl +'/homePage/updataHousePrice.json'},
  //房源相关权限
  houseRelevantAuthList: function () { return baseUrl +'/workbench/houseRelevantAuthList.json'},
  // ------------------------------------------ 房源相关 end ------------------------------------



  // ------------------------------------------ 统计 客户服务 begin ----------------------------------
  //总投诉量，投诉率
  complaintRatio: function () { return baseUrl +'/customer/complaintRatio.json'},
  //投诉类型占比
  complaintTypeRatio: function () { return baseUrl +'/customer/complaintTypeRatio.json'},
  //投诉处理情况
  complaintHandle: function () { return baseUrl +'/customer/complaintHandle.json'},



  // ------------------------------------------ 统计 客户服务 end ------------------------------------

  // ------------------------------------------ 统计 业绩 begin ----------------------------------
  //预估业绩、已结业绩
  estimateAchievement: function () { return baseUrl +'/achievementModule/achievement.json'},
  //业绩类型统计
  achievementType: function () { return baseUrl +'/achievementModule/achievementType.json'},
  //单笔情况
  single: function () { return baseUrl +'/achievementModule/single.json'},
  // ------------------------------------------ 统计 业绩 end ------------------------------------


  // ------------------------------------------ 统计 装修统计 begin ----------------------------------
  //平均装修时长
  decorationTimes: function () { return baseUrl +'/decoration/decorationTimes.json'},
  //套均装修款
  setDecoration: function () { return baseUrl +'/decoration/setDecoration.json'},
  //验收量
  acceptanceCount: function () { return baseUrl +'/decoration/acceptanceCount.json'},
  //智能门锁占比
  intelligentDoorLock: function () { return baseUrl +'/decoration/intelligentDoorLock.json'},
  //智能电表占比
  smartMeter: function () { return baseUrl +'/decoration/smartMeter.json'},
  //装修满意度
  decorationSatisfaction: function () { return baseUrl +'/decoration/decorationSatisfaction.json'},
  //间均月装修款
  monthDecoration: function () { return baseUrl +'/decoration/monthDecoration.json'},

  // ------------------------------------------ 统计 装修统计 end ------------------------------------


  // ------------------------------------------ 统计 用户画像 start ----------------------------------
  //租房价格区间
  rentPriceRange: function () { return baseUrl +'/userPortrayal/rentPriceRange.json'},
  //用户性别
  globaluserSex: function () { return baseUrl +'/userPortrayal/globaluserSex.json'},
  //用户性别
  globaluserAge: function () { return baseUrl +'/userPortrayal/globaluserAge.json'},
  //用户数量
  globaluserNum: function () { return baseUrl +'/userPortrayal/globaluserNum.json'},
  //违约用户数量
  breachGlobaluserNum: function () { return baseUrl +'/userPortrayal/breachGlobaluserNum.json'},
  //违约用户年龄
  breachGlobaluserAge: function () { return baseUrl +'/userPortrayal/breachGlobaluserAge.json'},
  //违约用户性别
  breachGlobaluserSex: function () { return baseUrl +'/userPortrayal/breachGlobaluserSex.json'},
  //用户付款方式占比
  userPaymantType: function () { return baseUrl +'/userPortrayal/userPaymantType.json'},
  // ------------------------------------------ 统计 用户画像 end ------------------------------------
  
  //获取用户对应权限
  userAuthList: function () { return baseUrl +'/workbench/dataStatisticAuthList.json'},

  // ------------------------------------------ 统计 集寓管理 start ----------------------------------
  //新签、续租、正退、违约
  projectInfo: function () { return baseUrl +'/apartmentInfo/projectInfo.json'},
  //集寓项目列表
  queryApartmentList: function () { return baseUrl +'/apartmentInfo/queryApartmentList.json'},
  //出租率
  queryApartmentInfo: function () { return baseUrl +'/apartmentInfo/queryApartmentInfo.json'},
  //平均房价
  avgHousePrice: function () { return baseUrl +'/apartmentInfo/avgHousePrice.json'},
  //续租占比
  renewProportion: function () { return baseUrl +'/apartmentInfo/renewProportion.json'},
  // ------------------------------------------ 统计 集寓管理 end ------------------------------------


  // --------------------------------------------------------贾萌 end--------------------------------------------
  //---------------------------------------------------周世岳  start---------------------------------------------
  //跟进列表初始化
  followUpList:function () { return baseUrl+'/workbench/followUpList.json'},
  //跟进保存
  followUpSave:function () { return baseUrl+'/workbench/followUpSave.json'},
  forgetPassword:function () { return baseUrl+'/login/forgetPassword.json'},
  bankCardBind:function () { return baseUrl+'/person/bankCardBind.json'},
  queryPersonDetails:function () { return baseUrl+'/person/queryPersonDetails.json'},
// passwordLogin:function () { return 'http://localhost:6060/oauth/token'},
  sendCode:function () { return baseUrl+'/login/sendCode.json'},
  contractList:function () { return baseUrl+'/contract/contractList.json'},
    //合同列表(包含租客,业主,预定) 2021/6/22 新增 王千赫
    contractListWithPercontract: function() { return baseUrl + '/contract/contractListWithPercontract.json'},
    //查询预定合同账单 2021/6/26 新增 王千赫
    queryPerContractBills: function() { return baseUrl + '/contract/queryPerContractBills.json'},
    //取消预定合同 2021/6/24 新增 王千赫
    cancelReserveContract: function() { return baseUrl + '/contract/cancelReserveContract.json'},
  //零钱明细
  userMoneyFlow:function () { return baseUrl+'/person/userMoneyFlow.json'},
//用户提现申请保存接口
  withdrawalApplicationSave:function () { return baseUrl+'/person/withdrawalApplicationSave.json'},
//用户资金详情接口
  userMoneyInfo:function () { return baseUrl+'/person/userMoneyInfo.json'},
//用户提现申请列表接口
  withdrawalApplicationList:function () { return baseUrl+'/person/withdrawalApplicationList.json'},
  passwordChange:function () { return baseUrl+'/person/passwordChange.json'},
  mobileChange:function () { return baseUrl+'/person/mobileChange.json'},
  //--------------------------------------------------周世岳  end-------------------------------------------------
  //---------------------------------------------------栾忠良  start---------------------------------------------
  //装修申请审核列表初始化页面接口
  decorationListInit:function () { return baseUrl+'/workbench/decorationListInit.json'},
  //装修申请页面接口
  decorationDetailInit:function () { return baseUrl+'/workbench/decorationDetailInit.json'},
  //潜房列表
  queryPotentialResources:function () { return baseUrl+'/workbench/queryPotentialResources.json'},
  //改变资源状态接口
  changeResourceStatus:function (){return baseUrl+'/workbench/changeResourceStatus.json'},
  //私盘转公盘的接口
  updatePotentialResourceBelog:function (){return baseUrl+'/workbench/updatePotentialResourceBelog.json'},
  //获取所有员工姓名的接口
  queryStaffList:function (){return baseUrl+'/person/queryStaffList.json'},
  //潜房详情接口
  //queryPotentialHouse : function (){return baseUrl+'/workbench/queryPotentialHouse.json'},
  //用户服务协议接口
  bannerLinkUser : function (){return baseUrl+'/photo/bannerLink.json'},
  //隐私协议接口
  bannerLinkUserPrivacyPolicy : function (){return baseUrl+'/photo/bannerLink.json'},
  //登录接口
  token: function(){return authUrl+'/oauth/token'},
  //忘记密码短信发送验证码接口
  forgetPasswordSendCode  : function(){ return baseUrl + '/login/forgetPasswordSendCode.json'},
  //绑定密码接口
  bindPassword : function(){return baseUrl + '/login/bindPassword.json'},
  //业主委托保存接口
  saveOwnerEntrust : function(){return baseUrl + '/ownerEntrust/saveOwnerEntrust.json'},
  //请假审批接口
  vacationListInit : function(){return baseUrl + '/workbench/vacationListInit.json'},
  //请假审批接口
  //browseApprovalList : function(){return baseUrl +'/approval/browseApprovalList.json'},
  //请假审核同意接口
  vacationAgree : function(){return baseUrl + '/workbench/vacationAgree.json'},
  //请假审核拒绝接口
  vacationRefuse : function(){return baseUrl + '/workbench/vacationRefuse.json'},
  //请假审核详情接口
  vacationDetailInit : function(){return baseUrl + '/workbench/vacationDetailInit.json'},
  //租后维修列表
  afterRentContList : function(){return baseUrl + '/afterRentCont/afterRentContList.json'},
  //维修派单保存接口
  saveRepairDistribute :  function(){return baseUrl + '/afterRentRepair/saveRepairDistribute.json'},
  //维修取消订单接口
  cancelRentRepairOrder :  function(){return baseUrl + '/afterRentRepair/cancelRentRepairOrder.json'},
  //维修订单删除接口
  repairDel :  function(){return baseUrl + '/afterRentRepair/repairDel.json'},
  //租后维修详情接口
  queryAfterRentRepair : function(){return baseUrl + '/afterRentRepair/queryAfterRentRepair.json'},
  //租后维修完成详情接口
  repairFinishDetail : function(){return baseUrl + '/afterRentRepair/repairFinishDetail.json'},
  //查询维修人员列表+维修派单头部数据
  queryRepairPersonnelList : function(){return baseUrl + '/afterRentRepair/queryRepairPersonnelList.json'},
  //维修完成保存接口
  saveAfterRentRepair : function(){return baseUrl + '/afterRentRepair/saveAfterRentRepair.json'},
  //维修验收保存
  savecheckRepair : function(){return baseUrl + '/afterRentRepair/savecheckRepair.json'},
  //维修验收详情
  queryRepairCheckDetail : function(){return baseUrl + '/afterRentRepair/queryRepairCheckDetail.json'},
  //维修详情
  queryRepairDetailCard : function(){return baseUrl + '/afterRentRepair/queryRepairDetailCard.json'},
  //维修改派保存接口
  saveChangeWorker : function(){return baseUrl + '/afterRentRepair/saveChangeWorker.json'},
  //维修验收记录接口
  queryRepairApprovalHistory : function(){return baseUrl + '/afterRentRepair/queryRepairApprovalHistory.json'},
  //维修获取密码
  userGetPassword : function(){return baseUrl + '/myHome/userGetPassword.json'},
  //保洁列表接口
  afterRentCleanList : function(){return baseUrl + '/afterRentClean/afterRentCleanList.json'},
  //保洁初始化接口
  queryCleanShirtInit : function(){return baseUrl + '/afterRentClean/queryCleanShirtInit.json'},
  //保洁派单保存
  saveCleanShirt : function(){return baseUrl + '/afterRentClean/saveCleanShirt.json'},
  //保洁改派初始化
  changeCleanerInit : function(){return baseUrl + '/afterRentClean/changeCleanerInit.json'},
  //保洁改派保存
  saveChangeCleaner : function(){return baseUrl + '/afterRentClean/saveChangeCleaner.json'},
  //保洁完成初始化
  finishCleaningHeadList : function(){return baseUrl + '/afterRentClean/finishCleaningHeadList.json'},
  //保洁完成保存接口
  saveFinishCleaning : function(){return baseUrl + '/afterRentClean/saveFinishCleaning.json'},
  //保洁取消订单接口
  cancelClean : function(){return baseUrl + '/afterRentClean/cancelClean.json'},
  //保洁验收初始化接口
  checkCleanInit : function(){return baseUrl + '/afterRentClean/checkCleanInit.json'},
  //保洁验收保存
  saveCheckClean : function(){return baseUrl + '/afterRentClean/saveCheckClean.json'},
  //保洁详情
  cleanDetails : function(){return baseUrl + '/afterRentClean/cleanDetails.json'},
  //潜客指派
  addAppoint : function(){return baseUrl + '/potentialUser/addAppoint.json'},
  //请假提交接口
  saveLeaveApply : function(){return baseUrl + '/person/saveLeaveApply.json'},
  //请假详情初始化、修改初始化
  staffVacationHistoryDetail : function(){return baseUrl + '/person/staffVacationHistoryDetail.json'},
  //请假列表初始化
  queryLeaveList : function(){return baseUrl + '/person/queryLeaveList.json'},
  //请假取消接口
  cancelLeave : function(){return baseUrl + '/person/cancelLeave.json'},
//--------------------------------------------------栾忠良  end-------------------------------------------------
  //--------------------------------------------------王秦胜  start-------------------------------------------------
  //寻找房源页面接口
  browseRoomList:function () { return baseUrl+'/findRoom/browseRoomList.json'},
  //房屋详情页面接口
  roomDetail:function () { return baseUrl+'/homePage/roomDetail.json'},
  //添加房屋详情图片接口
  saveHousePhoto:function () { return baseUrl+'/homePage/saveHousePhoto.json'},
  //查询房屋详情图片接口
  queryHousePhoto:function () { return baseUrl+'/homePage/queryHousePhoto.json'},
  //装修页面接口
  afterRentDecorationList:function () { return baseUrl+'/workbench/afterRentDecorationList.json'},
  //装修申请审核列表初始化页面接口
  decorationReviewListInit:function () { return baseUrl+'/workbench/decorationReviewListInit.json'},
  //装修申请通过接口
  decorationAgree:function () { return baseUrl+'/workbench/decorationAgree.json'},
  //装修申请驳回接口
  decorationRefuse:function () { return baseUrl+'/workbench/decorationRefuse.json'},
  //合同下变更租约初始化接口
  changeOfLeaseInit:function () { return baseUrl+'/contract/changeOfLeaseInit.json'},
  //变更租约申请接口
  userLeaseChangeSave:function () { return baseUrl+'/myHome/userLeaseChangeSave.json'},
  //物业交割单初始化接口
  queryContractProperty:function () { return baseUrl+'/contract/queryContractProperty.json'},
  //物业交割单确认接口
  confirmContractProperty:function () { return baseUrl+'/contract/confirmContractProperty.json'},
  //租后装修-更新进度
  updateProgressInfo:function () { return baseUrl+'/afterRentDecoration/updateProgressInfo.json'},
  //--------------------------------------------------王秦胜  end-------------------------------------------------
  //---------------------------------------------------张子尧  start---------------------------------------------
  //租约变更列表初始化接口
  userLeaseChangeList:function () { return baseUrl+'/myHome/userLeaseChangeList.json'},
  //租约变更取消接口
  userLeaseChangeCancel:function () { return baseUrl+'/myHome/userLeaseChangeCancel.json'},
  //租约变更详情初始化接口
  userLeaseChangeDetails:function () { return baseUrl+'/myHome/userLeaseChangeDetails.json'},
  //用户积分信息接口
  userIntegral:function () { return baseUrl+'/person/userIntegralInfo.json'},
  //用户账单列表接口
  userBillList:function () { return baseUrl+'/person/userBillList.json'},
  //待支付审批列表初始化
  queryPayBillList:function () { return baseUrl+'/workbench/queryPayBillList.json'},
  //批量审核
  approvalMergeBill:function () { return baseUrl+'/workbench/approvalMergeBill.json'},
  //优惠券列表接口
  userCouponList:function () { return baseUrl+'/person/userCouponList.json'},
  //工资列表初始化
  wageList:function () { return baseUrl+'/workbench/wageList.json'},
  //工资确认接口
  confirmWage:function () { return baseUrl+'/workbench/confirmWage.json'},
  //工资明细初始化接口
  wageDetailList:function () { return baseUrl+'/workbench/wageDetailList.json'},
  //工资补扣项明细初始化接口
  wageHistoryDetail:function () { return baseUrl+'/workbench/querySalaryHistoryAddtionList.json'},
  //新增预约带看接口
  addUserAppointmentNew:function () { return baseUrl+'/workbench/addUserAppointmentNew.json'},
  //取消预约合同
  cancelReservation: function () { return baseUrl +'/applet/percontract/cancelReservation.json'},
  //---------------------------------------------------张子尧  start---------------------------------------------
  //----------------------------------------------- 共通接口  start ----------------------------------------------
  //常量
  queryBaseData: function () { return baseUrl+'/manageCommon/queryBaseData.json'},
  getResetTariff: function () { return baseUrl+'/myHome/getResetTariff.json'},
  getStaffFuntionModelList: function () { return baseUrl+'/auth/getStaffFuntionModelList.json'},
  //----------------------------------------------- 共通接口  end ----------------------------------------------
  //---------------------------------------------------赵  start------------------------------------------------
  //提前付款天数
  findAdvanceDay: function () { return baseUrl+'/person/findAdvanceDay.json'},
  //签约预定合同
  perContractSign: function (){ return baseUrl+'/applet/rentercontract/perContractSign.json'},
  //修改租约变更查押金
  queryRestDepositMoneyBillNew: function () { return baseUrl + '/contract/queryRestDepositMoneyBillNew.json' },
  //忘记密码身份证验证
  certificateCodeVerification: function () { return baseUrl +'/login/certificateCodeVerification.json'},
  //查询租客
  queryRentTenant: function () { return baseUrl +'/person/queryRentTenant.json'},
  //判断上期账单是否已交
  billContinuity: function () { return baseUrl +'/person/billContinuity.json'},
  //负成本报价
  updataHouseSpecialOffer: function () { return baseUrl +'/homePage/updataHouseSpecialOffer.json'},

  policyDecisionData: function () {return baseUrl + '/person/policyDecisionData.json'},

  queryHouseResourcesOtherExpenses: function () {return baseUrl + '/homePage/queryHouseResourcesOtherExpenses.json'},

  updateVideo: function () {return baseUrl + '/photo/updateVideo.json'},

  compulsoryDissolution: function () {return baseUrl + '/contract/compulsoryDissolution.json'},

  intelligentHydropower: function () {return baseUrl + '/intelligentDevice/intelligentHydropower.json'},
  //供应商列表
  querySupplierList: function () {return baseUrl + '/afterRentDecoration/querySupplierList.json'},
  //删除视频
  delVideo: function () {return baseUrl + '/photo/delVideo.json'},
  //租后装修-更新进度
  updateProgressNew:function () { return baseUrl+'/afterRentDecoration/updateProgressNew.json'},
  //业主外置账单
  ownercontractCycle: function () {return baseUrl + '/contract/ownercontractCycle.json'},
  //消息通知数量
  queryBedealtCount: function () {return baseUrl + '/bedealt/queryBedealtCount.json'},  
  //消息通知列表接口
  queryBedealtList: function () {return baseUrl + '/bedealt/queryBedealtList.json'},
  //消息已读
  setBedealt: function () {return baseUrl + '/bedealt/setBedealt.json'},
  //是否支持老带新
  rentHouseNewTenant: function () {return baseUrl + '/rentercontract/rentHouseNewTenant.json'},

  browseSetHouseItem: function () {return baseUrl + '/homePage/browseSetHouseItem.json'},

  setHouseItemPhone: function () {return baseUrl + '/homePage/setHouseItemPhone.json'},

  queryMonth: function () {return baseUrl + '/myHome/queryMonth.json'},

  getDepositMoneyType: function () { return baseUrl + '/workbench/getDepositMoneyType.json' },

}
//测试
export const test = (data) => axios(UrlFactory.test(), JSON.stringify(encryption(data)), getAccessHeaders())
//   -------------------------------------------------------- 共通接口 start  ----------------------------------------------------
// 常量
export const queryBaseData = (data) => axios(UrlFactory.queryBaseData(), JSON.stringify(encryption(data)), getAccessHeaders())

// 充值电费
export const getResetTariff = (data) => axios(UrlFactory.getResetTariff(), JSON.stringify(encryption(data)), getAccessHeaders())

export const getStaffFuntionModelList = (data) => axios(UrlFactory.getStaffFuntionModelList(), JSON.stringify(encryption(data)), getAccessHeaders())
//   ----------------------------------------------------- 共通接口 end  -----------------------------------------------
//  -------------------------------------------------- 任英杰 接口测试 start  --------------------------------------------------
//1.预约列表
export const  querySeeWithList = (data) => axios(UrlFactory.querySeeWithList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客取消预约接口
export const  rentCancelSeeWith = (data) => axios(UrlFactory.rentCancelSeeWith(), JSON.stringify(encryption(data)), getAccessHeaders())
//维修项目初始化接口
export const  userRepairInit = (data) => axios(UrlFactory.userRepairInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//字典表接口
export const  getQueryBaseData = (data) => axios(UrlFactory.getQueryBaseData(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户租后取消申请
export const  userAfterRentCancel = (data) => axios(UrlFactory.userAfterRentCancel(), JSON.stringify(encryption(data)), getAccessHeaders())
//我的保洁列表
export const  querycleaningList = (data) => axios(UrlFactory.querycleaningList(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加投诉接口
export const  userAddComplaint = (data) => axios(UrlFactory.userAddComplaint(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客投诉列表初始化接口
export const  userComplaintList = (data) => axios(UrlFactory.userComplaintList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客投诉列表取消接口
export const  userCancelComplaint = (data) => axios(UrlFactory.userCancelComplaint(), JSON.stringify(encryption(data)), getAccessHeaders())
//维修保洁初始化接口
export const  userApplyInit = (data) => axios(UrlFactory.userApplyInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//维修保洁初始化接口
export const  userCleanApplySave = (data) => axios(UrlFactory.userCleanApplySave(), JSON.stringify(encryption(data)), getAccessHeaders())
//预约合同初始化接口
export const  findPerContractByRoomId = (data) => axios(UrlFactory.findPerContractByRoomId(), JSON.stringify(encryption(data)), getAccessHeaders())
//预约合同初始化接口
export const  percontractsaveAndUpdatePerContractList = (data) => axios(UrlFactory.percontractsaveAndUpdatePerContractList(), JSON.stringify(encryption(data)), getAccessHeaders())
//维修申请保存接口
export const  uploadImage = (data) => axios(UrlFactory.uploadImage(), JSON.stringify(encryption(data)), getAccessHeaders())
//图片上传接口
export const  userRepairApplySave = (data) => axios(UrlFactory.userRepairApplySave(), JSON.stringify(encryption(data)), getAccessHeaders())
//预约合同列表查询
export const  percontractqueyPerContractList = (data) => axios(UrlFactory.percontractqueyPerContractList(), JSON.stringify(encryption(data)), getAccessHeaders())
//预约带看选择房源接口
export const  seeWithRoomList = (data) => axios(UrlFactory.seeWithRoomList(), JSON.stringify(encryption(data)), getAccessHeaders())
//带看申请
export const  addUserAppointment = (data) => axios(UrlFactory.addUserAppointment(), JSON.stringify(encryption(data)), getAccessHeaders())
//带看初始化接口
export const  seeWithInit = (data) => axios(UrlFactory.seeWithInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//获取密码锁接口
export const  seeWithGetPassword = (data) => axios(UrlFactory.seeWithGetPassword(), JSON.stringify(encryption(data)), getAccessHeaders())
//确认带看录入接口
export const  seeWithComfirm = (data) => axios(UrlFactory.seeWithComfirm(), JSON.stringify(encryption(data)), getAccessHeaders())
//房源地址初始化接口
export const  userRepairAddressList = (data) => axios(UrlFactory.userRepairAddressList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租后配货
export const  afterRentDistributionList = (data) => axios(UrlFactory.afterRentDistributionList(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单初始化接口
export const  saveDistributionOrderInit = (data) => axios(UrlFactory.saveDistributionOrderInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单初始化接口
export const  distributionDetailItemInit = (data) => axios(UrlFactory.distributionDetailItemInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单保存接口
export const  saveDistributionOrder = (data) => axios(UrlFactory.saveDistributionOrder(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货取消接口
export const  cancelDistribution = (data) => axios(UrlFactory.cancelDistribution(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货验收初始化接口
export const  distributionDetailDispatchInit = (data) => axios(UrlFactory.distributionDetailDispatchInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货验收验收接口
export const  saveDistributionAcceptance = (data) => axios(UrlFactory.saveDistributionAcceptance(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单初始化接口
export const  changeDistributionInit = (data) => axios(UrlFactory.changeDistributionInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单保存接口
export const  saveChangeDistribution = (data) => axios(UrlFactory.saveChangeDistribution(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单保存接口
export const  deliveryCompletedHeadList = (data) => axios(UrlFactory.deliveryCompletedHeadList(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货派单保存接口
export const  saveFinishCompleted = (data) => axios(UrlFactory.saveFinishCompleted(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货申请详情接口
export const  distributionDetailInitList = (data) => axios(UrlFactory.distributionDetailInitList(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货完成详情接口
export const  distributionDetailFinishInit = (data) => axios(UrlFactory.distributionDetailFinishInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货完成详情接口
export const  queryDistributionApprovalHistory = (data) => axios(UrlFactory.queryDistributionApprovalHistory(), JSON.stringify(encryption(data)), getAccessHeaders())
//房间查询
export const  getPerRoomHouseList = (data) => axios(UrlFactory.getPerRoomHouseList(), JSON.stringify(encryption(data)), getAccessHeaders())
//新增预约初始化接口
export const  getRoomHouseListById = (data) => axios(UrlFactory.getRoomHouseListById(), JSON.stringify(encryption(data)), getAccessHeaders())
//新增预约初始化接口
export const  getUserList = (data) => axios(UrlFactory.getUserList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客选择承租人（用户对应的潜客）
export const  queryStaffPotentialUser = (data) => axios(UrlFactory.queryStaffPotentialUser(), JSON.stringify(encryption(data)), getAccessHeaders())
//  -------------------------------------------------- 任英杰 接口测试 end  --------------------------------------------------
//  -------------------------------------------------- 洪森 接口测试 start  --------------------------------------------------
//1.收房申请审核列表页面
export const  reviewOfApplyForHouseList = (data) => axios(UrlFactory.reviewOfApplyForHouseList(), JSON.stringify(encryption(data)), getAccessHeaders())
//2.潜房详情页面接口
export const  queryPotentialHouse = (data) => axios(UrlFactory.queryPotentialHouse(), JSON.stringify(encryption(data)), getAccessHeaders())
//3.收房申请详情接口
export const  colllectRoomDetail = (data) => axios(UrlFactory.colllectRoomDetail(), JSON.stringify(encryption(data)), getAccessHeaders())
//4.收房申请同意接口
export const  collectRoomAgree = (data) => axios(UrlFactory.collectRoomAgree(), JSON.stringify(encryption(data)), getAccessHeaders())
//5.收房申请拒绝接口
export const  collectRoomRefuse = (data) => axios(UrlFactory.collectRoomRefuse(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修申请负责人列表，设计师列表，费用承担方列表初始化接口
export const  returnChargeAndDesignerList = (data) => axios(UrlFactory.returnChargeAndDesignerList(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同详情初始化
export const  findOwnerContractInfo = (data) => axios(UrlFactory.findOwnerContractInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
//合同下的物业交割单
export const  findContractproperty = (data) => axios(UrlFactory.findContractproperty(), JSON.stringify(encryption(data)), getAccessHeaders())
//物业交割单初始化查询水表号等
export const  getMeterNumberAndMore = (data) => axios(UrlFactory.getMeterNumberAndMore(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同账单信息
export const  queryOwnerContractBill = (data) => axios(UrlFactory.queryOwnerContractBill(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同账单信息
export const  queryRenterContractBill = (data) => axios(UrlFactory.queryRenterContractBill(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同退租信息
export const  findContractHistoryDetail = (data) => axios(UrlFactory.findContractHistoryDetail(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同审批信息
export const  findApprovalHistory = (data) => axios(UrlFactory.findApprovalHistory(), JSON.stringify(encryption(data)), getAccessHeaders())
//电子合同查看
export const  findElectronicContractUrl = (data) => axios(UrlFactory.findElectronicContractUrl(), JSON.stringify(encryption(data)), getAccessHeaders())
//租约变更列表初始化信息
export const  findCancelContractApprovalList = (data) => axios(UrlFactory.findCancelContractApprovalList(), JSON.stringify(encryption(data)), getAccessHeaders())
//审核详情初始化
export const  renterRetreatDetails = (data) => axios(UrlFactory.renterRetreatDetails(), JSON.stringify(encryption(data)), getAccessHeaders())
//租约变更申请同意接口
export const  chanageContractAgree = (data) => axios(UrlFactory.chanageContractAgree(), JSON.stringify(encryption(data)), getAccessHeaders())
//租约变更申请拒绝接口
export const  chanageContractRefuse = (data) => axios(UrlFactory.chanageContractRefuse(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同列表签约接口
export const  ownerContractSign = (data) => axios(UrlFactory.ownerContractSign(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主合同列表废除接口
export const  contractInvalid = (data) => axios(UrlFactory.contractInvalid(), JSON.stringify(encryption(data)), getAccessHeaders())
//业主或者租客解除合同详情初始化
export const  approvalContractDetail = (data) => axios(UrlFactory.approvalContractDetail(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客合同新增或者修改
export const  saveOrUpdateRenterContract = (data) => axios(UrlFactory.saveOrUpdateRenterContract(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客合同房源列表
export const  getRenterRoomHouseList = (data) => axios(UrlFactory.getRenterRoomHouseList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客合同中根据房间id查询部分房间信息
export const  findRentByRoomId = (data) => axios(UrlFactory.findRentByRoomId(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客合同中根据房间id查询对应的业主合同的截止时间
export const  querygOwnerContractEndDate = (data) => axios(UrlFactory.querygOwnerContractEndDate(), JSON.stringify(encryption(data)), getAccessHeaders())

//租客合同中根据房间id查询对应的业主合同的截止时间
export const  queryRenterFeePolicyDecision = (data) => axios(UrlFactory.queryRenterFeePolicyDecision(), JSON.stringify(encryption(data)), getAccessHeaders())

//查询租客合同详情
export const  renterContractInfo = (data) => axios(UrlFactory.renterContractInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
//查剩余押金方法
export const queryRestDepositMoneyBill = (data) => axios(UrlFactory.queryRestDepositMoneyBill(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客合同列表签字功能
export const  renterContractSign = (data) => axios(UrlFactory.renterContractSign(), JSON.stringify(encryption(data)), getAccessHeaders())
//根据电话号查询租客列表
export const  remoteMobileSearch = (data) => axios(UrlFactory.remoteMobileSearch(), JSON.stringify(encryption(data)), getAccessHeaders())
//根据房间id查询优惠券
export const  getCouponList = (data) => axios(UrlFactory.getCouponList(), JSON.stringify(encryption(data)), getAccessHeaders())
//根据潜客资源id查询潜客对应的预定合同
export const  queryPotentialUserRequireRoomHouseId = (data) => axios(UrlFactory.queryPotentialUserRequireRoomHouseId(), JSON.stringify(encryption(data)), getAccessHeaders())
// ------------------------------------------------------洪森 接口测试 end   ---------------------------------------------
//  -------------------------------------------------- 李大力 接口测试 start  --------------------------------------------------
export const  saveOrUpdateOwnercontract = (data) => axios(UrlFactory.ownercontractsaveOrUpdateOwnercontract(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  createContractCode = (data) => axios(UrlFactory.ownercontractcreateContractCode(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  findDepartmentByStaffId = (data) => axios(UrlFactory.findDepartmentByStaffId(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  queryrecommendUser = (data) => axios(UrlFactory.queryrecommendUser(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  findUserInfoByMobile = (data) => axios(UrlFactory.findUserInfoByMobile(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  ownercontractfindOwnerContractInfo = (data) => axios(UrlFactory.ownercontractfindOwnerContractInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  roomCollectionList = (data) => axios(UrlFactory.roomCollectionList(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  queryCityList = (data) => axios(UrlFactory.queryCityList(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  potentialUserList = (data) => axios(UrlFactory.potentialUserList(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  distributionListInit = (data) => axios(UrlFactory.distributionListInit(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  queryPotentialUser = (data) => axios(UrlFactory.queryPotentialUser(), JSON.stringify(encryption(data)), getAccessHeaders())

export const  seeWithList = (data) => axios(UrlFactory.seeWithList(), JSON.stringify(encryption(data)), getAccessHeaders())
// export const  browseSeeWith = (data) => axios(UrlFactory.browseSeeWith(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货申请详情初始化
export const  queryAfterRentDistribution = (data) => axios(UrlFactory.queryAfterRentDistribution(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货审核同意接口
export const  distributionAgree = (data) => axios(UrlFactory.distributionAgree(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货审核拒绝接口
export const  distributionRefuse = (data) => axios(UrlFactory.distributionRefuse(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  addOrUpdatePotentialUser = (data) => axios(UrlFactory.addOrUpdatePotentialUser(), JSON.stringify(encryption(data)), getAccessHeaders())
// export const  businessCircleList = (data) => axios(UrlFactory.businessCircleList(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  queryBusinessCircle = (data) => axios(UrlFactory.queryBusinessCircle(), JSON.stringify(encryption(data)), getAccessHeaders())
//绑定手机接口
export const  bindMobile = (data) => axios(UrlFactory.bindMobile(), JSON.stringify(encryption(data)), getAccessHeaders())
//我的业绩初始化
export const  browseStaffAchievementList = (data) => axios(UrlFactory.browseStaffAchievementList(), JSON.stringify(encryption(data)), getAccessHeaders())
//我的业绩初始化
export const  queryStaffAchievementCount = (data) => axios(UrlFactory.queryStaffAchievementCount(), JSON.stringify(encryption(data)), getAccessHeaders())

//业绩详情初始化
export const  achievementDetail = (data) => axios(UrlFactory.achievementDetail(), JSON.stringify(encryption(data)), getAccessHeaders())

// ------------------------------------------------------李大力 接口测试 end   ---------------------------------------------
//  -----------------------------------------------------贾萌 接口测试 start  --------------------------------------------
//推荐简历初始化接口
export const RecommendResumeInit = (data) => axios(UrlFactory.RecommendResumeInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//推荐简历接口保存
export const RecommendResumeSave = (data) => axios(UrlFactory.RecommendResumeSave(), JSON.stringify(encryption(data)), getAccessHeaders())
//实勘初始化
export const queryExploration = (data) => axios(UrlFactory.queryExploration(), JSON.stringify(encryption(data)), getAccessHeaders())
//实勘保存接口
export const explorationSave = (data) => axios(UrlFactory.explorationSave(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加潜房接口
export const addOrUpdatePotentialHouse = (data) => axios(UrlFactory.addOrUpdatePotentialHouse(), JSON.stringify(encryption(data)), getAccessHeaders())
//楼盘列表接口
export const queryEstateList = (data) => axios(UrlFactory.queryEstateList(), JSON.stringify(encryption(data)), getAccessHeaders())
//栋座列表接口
export const queryEstatePedestalList = (data) => axios(UrlFactory.queryEstatePedestalList(), JSON.stringify(encryption(data)), getAccessHeaders())
//单元列表接口
export const queryEstateUnitList = (data) => axios(UrlFactory.queryEstateUnitList(), JSON.stringify(encryption(data)), getAccessHeaders())
//房屋列表接口
export const queryEstateRoomDetailList = (data) => axios(UrlFactory.queryEstateRoomDetailList(), JSON.stringify(encryption(data)), getAccessHeaders())
//收房申请添加接口
export const addcolllectRoom = (data) => axios(UrlFactory.addcolllectRoom(), JSON.stringify(encryption(data)), getAccessHeaders())
//实名认证初始化接口
export const queryNameCertification = (data) => axios(UrlFactory.queryNameCertification(), JSON.stringify(encryption(data)), getAccessHeaders())
//实名认证接口
export const nameCertification = (data) => axios(UrlFactory.nameCertification(), JSON.stringify(encryption(data)), getAccessHeaders())
//保存用户身份证图片
export const saveUserPhoto = (data) => axios(UrlFactory.saveUserPhoto(), JSON.stringify(encryption(data)), getAccessHeaders())
//图片base64上传接口
export const base64Upload = (data) => axios(UrlFactory.base64Upload(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加潜房推荐人列表
export const seeWithUserList = (data) => axios(UrlFactory.seeWithUserList(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加潜客推荐人列表（新）
export const findRecommendUser = (data) => axios(UrlFactory.findRecommendUser(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修查看详情
export const queryRenovation = (data) => axios(UrlFactory.queryRenovation(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修查看审批记录
export const afterrentHisotry = (data) => axios(UrlFactory.afterrentHisotry(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修查看审批记录
export const browseBillList = (data) => axios(UrlFactory.browseBillList(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加合同物业交割单
export const addContractproperty = (data) => axios(UrlFactory.addContractproperty(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询物品分类list
export const queryAllocationClass = (data) => axios(UrlFactory.queryAllocationClass(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询物品list
export const queryAllocation = (data) => axios(UrlFactory.queryAllocation(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修详情施工方案详情
export const decorationConstructionDetails = (data) => axios(UrlFactory.decorationConstructionDetails(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询用户身份证照片接口
export const queryIdPhoto = (data) => axios(UrlFactory.queryIdPhoto(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修保存改派接口
export const saveReformSchool = (data) => axios(UrlFactory.saveReformSchool(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修初始化改派接口
export const showReformSchool = (data) => axios(UrlFactory.showReformSchool(), JSON.stringify(encryption(data)), getAccessHeaders())
// 删除装修
export const delDecoration = (data) => axios(UrlFactory.delDecoration(), JSON.stringify(encryption(data)), getAccessHeaders())
// 取消装修
export const cancelDecoration = (data) => axios(UrlFactory.cancelDecoration(), JSON.stringify(encryption(data)), getAccessHeaders())
// 装修派单初始化
export const decoratListInit = (data) => axios(UrlFactory.decoratListInit(), JSON.stringify(encryption(data)), getAccessHeaders())
// 装修派单保存
export const saveDecorationList = (data) => axios(UrlFactory.saveDecorationList(), JSON.stringify(encryption(data)), getAccessHeaders())
// 装配方案初始化
export const showProgramme = (data) => axios(UrlFactory.showProgramme(), JSON.stringify(encryption(data)), getAccessHeaders())
// 保存装配方案
export const updateRenovationProgramme = (data) => axios(UrlFactory.updateRenovationProgramme(), JSON.stringify(encryption(data)), getAccessHeaders())
// 保存装修质量验收
export const saveAcceptance = (data) => axios(UrlFactory.saveAcceptance(), JSON.stringify(encryption(data)), getAccessHeaders())
// 已申请提现金额
export const userCanApplyMoneyInfo = (data) => axios(UrlFactory.userCanApplyMoneyInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉列表初始化
export const afterRentComplaintList = (data) => axios(UrlFactory.afterRentComplaintList(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉详情初始化
export const afterRentComplaintAcceptInit = (data) => axios(UrlFactory.afterRentComplaintAcceptInit(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉历史初始化
export const queryComplaintHistory = (data) => axios(UrlFactory.queryComplaintHistory(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后删除投诉记录
export const removeAfterRentComplaint = (data) => axios(UrlFactory.removeAfterRentComplaint(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后保存租后受理
export const saveAfterRentComplaintAccept = (data) => axios(UrlFactory.saveAfterRentComplaintAccept(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后完成投诉
export const updateAfterRentComplaint = (data) => axios(UrlFactory.updateAfterRentComplaint(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉核验
export const updateAfterRentComplaintCheck = (data) => axios(UrlFactory.updateAfterRentComplaintCheck(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉核验
export const complaintApplicationListInit = (data) => axios(UrlFactory.complaintApplicationListInit(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉核验
export const queryDepartment = (data) => axios(UrlFactory.queryDepartment(), JSON.stringify(encryption(data)), getAccessHeaders())
// 租后投诉受理找部门
export const intelligentDeviceList = (data) => axios(UrlFactory.intelligentDeviceList(), JSON.stringify(encryption(data)), getAccessHeaders())


// --------------------------------------------------------贾萌 接口测试 end ---------------------------------------------
//  ------------------------------------------------------栾忠良 接口 start-----------------------------------------------
//1.装修申请审核列表初化页面
export const decorationListInit  = (data) => axios(UrlFactory.decorationListInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//2申请审核详情
export const decorationDetailInit  = (data) => axios(UrlFactory.decorationDetailInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//潜房列表
export const queryPotentialResources  = (data) => axios(UrlFactory.queryPotentialResources(), JSON.stringify(encryption(data)), getAccessHeaders())
//改变资源状态接口
export const changeResourceStatus  = (data) => axios(UrlFactory.changeResourceStatus(), JSON.stringify(encryption(data)), getAccessHeaders())
//公盘转私盘的接口
export const updatePotentialResourceBelog  = (data) => axios(UrlFactory.updatePotentialResourceBelog(), JSON.stringify(encryption(data)), getAccessHeaders())
//获取所有员工姓名的接口
export const queryStaffList  = (data) => axios(UrlFactory.queryStaffList(), JSON.stringify(encryption(data)), getAccessHeaders())
//潜房详情接口
//export const queryPotentialHouse  = (data) => axios(UrlFactory.queryPotentialHouse(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户服务接口
export const bannerLinkUser = (data) =>axios(UrlFactory.bannerLinkUser(),JSON.stringify(encryption(data)),getAccessHeaders())
//用户隐私协议接口
export const bannerLinkUserPrivacyPolicy = (data) =>axios(UrlFactory.bannerLinkUserPrivacyPolicy(),JSON.stringify(encryption(data)),getAccessHeaders())
//用户登录接口
export const token = (data) =>axios(UrlFactory.token(),data,getAuthHeaders())
//忘记密码短信发送接口
export const forgetPasswordSendCode = (data) =>axios(UrlFactory.forgetPasswordSendCode(),JSON.stringify(encryption(data)),getAccessHeaders())
//绑定密码接口
export const bindPassword = (data) =>axios(UrlFactory.bindPassword(),JSON.stringify(encryption(data)),getAccessHeaders())
//业主委托保存接口
export const saveOwnerEntrust = (data) =>axios(UrlFactory.saveOwnerEntrust(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假审核接口
export const vacationListInit = (data) =>axios(UrlFactory.vacationListInit(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假审批接口
export const browseApprovalList = (data) =>axios(UrlFactory.browseApprovalList(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假审核同意接口
export  const vacationAgree = (data) =>axios(UrlFactory.vacationAgree(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假审核拒绝接口
export  const vacationRefuse = (data) =>axios(UrlFactory.vacationRefuse(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假审核详情接口
export const vacationDetailInit = (data) =>axios(UrlFactory.vacationDetailInit(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修列表接口
export const afterRentContList = (data) =>axios(UrlFactory.afterRentContList(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修派单保存接口
export const saveRepairDistribute = (data) =>axios(UrlFactory.saveRepairDistribute(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修取消订单接口
export const cancelRentRepairOrder = (data) =>axios(UrlFactory.cancelRentRepairOrder(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修订单删除接口
export const repairDel = (data) =>axios(UrlFactory.repairDel(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修详情接口
export const queryAfterRentRepair = (data) =>axios(UrlFactory.queryAfterRentRepair(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修完成详情接口
export const repairFinishDetail = (data) =>axios(UrlFactory.repairFinishDetail(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修查询维修人员
export const queryRepairPersonnelList = (data) =>axios(UrlFactory.queryRepairPersonnelList(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修完成保存接口
export const saveAfterRentRepair = (data) =>axios(UrlFactory.saveAfterRentRepair(),JSON.stringify(encryption(data)),getAccessHeaders())
//租后维修验收保存
export const savecheckRepair = (data) =>axios(UrlFactory.savecheckRepair(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修验收详情
export const queryRepairCheckDetail = (data) =>axios(UrlFactory.queryRepairCheckDetail(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修详情
export const queryRepairDetailCard = (data) =>axios(UrlFactory.queryRepairDetailCard(),JSON.stringify(encryption(data)),getAccessHeaders())
//改派保存接口
export const saveChangeWorker = (data) =>axios(UrlFactory.saveChangeWorker(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修验收记录接口
export const queryRepairApprovalHistory = (data) =>axios(UrlFactory.queryRepairApprovalHistory(),JSON.stringify(encryption(data)),getAccessHeaders())
//维修查看密码
export const userGetPassword = (data) =>axios(UrlFactory.userGetPassword(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁列表
export const afterRentCleanList = (data) =>axios(UrlFactory.afterRentCleanList(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁派单初始化
export const queryCleanShirtInit = (data) =>axios(UrlFactory.queryCleanShirtInit(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁派单保存
export const saveCleanShirt = (data) =>axios(UrlFactory.saveCleanShirt(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁改派初始化
export const changeCleanerInit = (data) =>axios(UrlFactory.changeCleanerInit(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁改派保存
export const saveChangeCleaner = (data) =>axios(UrlFactory.saveChangeCleaner(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁完成初始化
export const finishCleaningHeadList = (data) =>axios(UrlFactory.finishCleaningHeadList(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁完成保存接口
export const saveFinishCleaning = (data) =>axios(UrlFactory.saveFinishCleaning(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁取消订单接口
export const cancelClean = (data) =>axios(UrlFactory.cancelClean(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁验收初始化接口
export const checkCleanInit = (data) =>axios(UrlFactory.checkCleanInit(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁验收保存
export const saveCheckClean = (data) =>axios(UrlFactory.saveCheckClean(),JSON.stringify(encryption(data)),getAccessHeaders())
//保洁详情
export const cleanDetails = (data) =>axios(UrlFactory.cleanDetails(),JSON.stringify(encryption(data)),getAccessHeaders())
//潜客私客指派
export const addAppoint = (data) =>axios(UrlFactory.addAppoint(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假申请提交接口
export const saveLeaveApply = (data) =>axios(UrlFactory.saveLeaveApply(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假详情初始化、修改初始化
export const staffVacationHistoryDetail = (data) =>axios(UrlFactory.staffVacationHistoryDetail(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假取消接口
export const cancelLeave = (data) =>axios(UrlFactory.cancelLeave(),JSON.stringify(encryption(data)),getAccessHeaders())
//请假列表接口初始化
export const queryLeaveList = (data) =>axios(UrlFactory.queryLeaveList(),JSON.stringify(encryption(data)),getAccessHeaders())

//  ------------------------------------------------------栾忠良 接口 end  -----------------------------------------------
//  ------------------------------------------------------周世岳 接口 start-----------------------------------------------
//跟进列表初始化
export const followUpList = (data) => axios(UrlFactory.followUpList(), JSON.stringify(encryption(data)), getAccessHeaders())
//跟进保存接口
export const followUpSave = (data) => axios(UrlFactory.followUpSave(), JSON.stringify(encryption(data)), getAccessHeaders())
export const passwordChange = (data) => axios(UrlFactory.passwordChange(), JSON.stringify(encryption(data)), getAccessHeaders())
export const forgetPassword = (data) => axios(UrlFactory.forgetPassword(), JSON.stringify(encryption(data)), getAccessHeaders())
export const bankCardBind = (data) => axios(UrlFactory.bankCardBind(), JSON.stringify(encryption(data)), getAccessHeaders())
export const mobileChange = (data) => axios(UrlFactory.mobileChange(), JSON.stringify(encryption(data)), getAccessHeaders())
export const queryPersonDetails = (data) => axios(UrlFactory.queryPersonDetails(), JSON.stringify(encryption(data)), getAccessHeaders())
export const passwordLogin = (data) => axios(UrlFactory.passwordLogin(), JSON.stringify(encryption(data)), getAccessHeaders())
export const sendCode = (data) => axios(UrlFactory.sendCode(), JSON.stringify(encryption(data)), getAccessHeaders())
export const contractList = (data) => axios(UrlFactory.contractList(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询合同列表(租客,预定,业主) 2021/06/22新增 王千赫
export const contractListWithPercontract=(data) => axios(UrlFactory.contractListWithPercontract(),JSON.stringify(encryption(data)),getAccessHeaders())
//查询预定合同账单 2021/06/25新增 王千赫
export const queryPerContractBills = (data) => axios(UrlFactory.queryPerContractBills(),JSON.stringify(encryption(data)),getAccessHeaders())
//取消预定合同 2021/06/24新增 王千赫
export const cancelReserveContract = (data) => axios(UrlFactory.cancelReserveContract(),JSON.stringify(encryption(data)), getAccessHeaders())
//零钱明细
export const userMoneyFlow = (data) => axios(UrlFactory.userMoneyFlow(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户提现申请保存接口
export const withdrawalApplicationSave = (data) => axios(UrlFactory.withdrawalApplicationSave(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户资金详情接口
export const userMoneyInfo = (data) => axios(UrlFactory.userMoneyInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户提现申请列表接口
export const withdrawalApplicationList = (data) => axios(UrlFactory.withdrawalApplicationList(), JSON.stringify(encryption(data)), getAccessHeaders())
//  ------------------------------------------------------周世岳 接口 end  -----------------------------------------------
//  ------------------------------------------------------王秦胜 接口 start-----------------------------------------------
//1.寻找房源列表初化页面
export const browseRoomList  = (data) => axios(UrlFactory.browseRoomList(), JSON.stringify(encryption(data)), getAccessHeaders())
//2.房源详情初化页面
export const roomDetail  = (data) => axios(UrlFactory.roomDetail(), JSON.stringify(encryption(data)), getAccessHeaders())
//3.装修初始化页面
export const afterRentDecorationList  = (data) => axios(UrlFactory.afterRentDecorationList(), JSON.stringify(encryption(data)), getAccessHeaders())
//4.装修申请审核初始化页面
export const decorationReviewListInit  = (data) => axios(UrlFactory.decorationReviewListInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//5.装修申请通过
export const decorationAgree  = (data) => axios(UrlFactory.decorationAgree(), JSON.stringify(encryption(data)), getAccessHeaders())
//6.装修申请驳回
export const decorationRefuse  = (data) => axios(UrlFactory.decorationRefuse(), JSON.stringify(encryption(data)), getAccessHeaders())
//7.合同下变更租约初始化接口
export const changeOfLeaseInit  = (data) => axios(UrlFactory.changeOfLeaseInit(), JSON.stringify(encryption(data)), getAccessHeaders())
//8.变更租约申请接口
export const userLeaseChangeSave  = (data) => axios(UrlFactory.userLeaseChangeSave(), JSON.stringify(encryption(data)), getAccessHeaders())
//9.物业交割单初始化接口
export const queryContractProperty  = (data) => axios(UrlFactory.queryContractProperty(), JSON.stringify(encryption(data)), getAccessHeaders())
//10.物业交割单确认接口
export const confirmContractProperty  = (data) => axios(UrlFactory.confirmContractProperty(), JSON.stringify(encryption(data)), getAccessHeaders())
//11.租后装修更新进度-完成
export const updateProgressInfo  = (data) => axios(UrlFactory.updateProgressInfo(), JSON.stringify(encryption(data)), getAccessHeaders())

export const saveHousePhoto  = (data) => axios(UrlFactory.saveHousePhoto(), JSON.stringify(encryption(data)), getAccessHeaders())
export const queryHousePhoto  = (data) => axios(UrlFactory.queryHousePhoto(), JSON.stringify(encryption(data)), getAccessHeaders())
//  ------------------------------------------------------王秦胜 接口 end  -----------------------------------------------
// ----------------------------------------------------韦愉超  接口  start-------------------------------------------------
//推荐有礼页面
export const userIntegralInfo  = (data) => axios(UrlFactory.userIntegralInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
// ----------------------------------------------------韦愉超  接口  end-------------------------------------------------
// ----------------------------------------------------张子尧  接口  start-------------------------------------------------
//租约变更列表初始化接口
export const  userLeaseChangeList = (data) => axios(UrlFactory.userLeaseChangeList(), JSON.stringify(encryption(data)), getAccessHeaders())
//租约变取消接口
export const  userLeaseChangeCancel = (data) => axios(UrlFactory.userLeaseChangeCancel(), JSON.stringify(encryption(data)), getAccessHeaders())
//租约变更详情初始化接口
export const  userLeaseChangeDetails = (data) => axios(UrlFactory.userLeaseChangeDetails(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户积分信息接口
export const  userIntegral = (data) => axios(UrlFactory.userIntegral(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户账单列表接口
export const  userBillList = (data) => axios(UrlFactory.userBillList(), JSON.stringify(encryption(data)), getAccessHeaders())
//待支付审批列表初始化
export const  queryPayBillList = (data) => axios(UrlFactory.queryPayBillList(), JSON.stringify(encryption(data)), getAccessHeaders())
//批量审核
export const  approvalMergeBill = (data) => axios(UrlFactory.approvalMergeBill(), JSON.stringify(encryption(data)), getAccessHeaders())
//优惠券列表接口
export const  userCouponList = (data) => axios(UrlFactory.userCouponList(), JSON.stringify(encryption(data)), getAccessHeaders())
//工资列表初始化
export const  wageList = (data) => axios(UrlFactory.wageList(), JSON.stringify(encryption(data)), getAccessHeaders())
//工资确认接口
export const  confirmWage = (data) => axios(UrlFactory.confirmWage(), JSON.stringify(encryption(data)), getAccessHeaders())
//工资明细初始化接口
export const  wageDetailList = (data) => axios(UrlFactory.wageDetailList(), JSON.stringify(encryption(data)), getAccessHeaders())
//工资补扣项明细初始化接口
export const  wageHistoryDetail = (data) => axios(UrlFactory.wageHistoryDetail(), JSON.stringify(encryption(data)), getAccessHeaders())
//新增预约带看接口
export const  addUserAppointmentNew = (data) => axios(UrlFactory.addUserAppointmentNew(), JSON.stringify(encryption(data)), getAccessHeaders())
//取消预约合同
export const  cancelReservation = (data) => axios(UrlFactory.cancelReservation(), JSON.stringify(encryption(data)), getAccessHeaders())
// ----------------------------------------------------张子尧  接口  end-------------------------------------------------

export const  getUserPassword = (data) => axios(UrlFactory.getUserPassword(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  updateRoomEquipmentPassword = (data) => axios(UrlFactory.updateRoomEquipmentPassword(), JSON.stringify(encryption(data)), getAccessHeaders())
export const  getUserPowerInfo = (data) => axios(UrlFactory.getUserPowerInfo(), JSON.stringify(encryption(data)), getAccessHeaders())


//-------------------------------------- 统计 基础人事 begin -----------------------------------------
//中后台占比
export const  backstageData = (data) => axios(UrlFactory.backstageData(), JSON.stringify(encryption(data)), getAccessHeaders())
//职务级别占比
export const  inJobData = (data) => axios(UrlFactory.inJobData(), JSON.stringify(encryption(data)), getAccessHeaders())
//入职渠道
export const  entryChannelsData = (data) => axios(UrlFactory.entryChannelsData(), JSON.stringify(encryption(data)), getAccessHeaders())
//人员流动情况（离职量、入职量）
export const  personnelTurnoverData = (data) => axios(UrlFactory.personnelTurnoverData(), JSON.stringify(encryption(data)), getAccessHeaders())
//在职时间分析
export const  workingHoursData = (data) => axios(UrlFactory.workingHoursData(), JSON.stringify(encryption(data)), getAccessHeaders())
//员工年龄
export const  staffAgeData = (data) => axios(UrlFactory.staffAgeData(), JSON.stringify(encryption(data)), getAccessHeaders())
//员工性别
export const  staffSexData = (data) => axios(UrlFactory.staffSexData(), JSON.stringify(encryption(data)), getAccessHeaders())
//员工学历
export const  educationData = (data) => axios(UrlFactory.educationData(), JSON.stringify(encryption(data)), getAccessHeaders())
//员工学历
export const  staffLeavingData = (data) => axios(UrlFactory.staffLeavingData(), JSON.stringify(encryption(data)), getAccessHeaders())
//-------------------------------------- 统计 基础人事 end -------------------------------------------

//-------------------------------------- 统计 财务统计 begin -----------------------------------------
//房屋账单
export const  housesBill = (data) => axios(UrlFactory.housesBill(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修回款
export const  renovationFund = (data) => axios(UrlFactory.renovationFund(), JSON.stringify(encryption(data)), getAccessHeaders())
//应收，应支
export const  receivablePay = (data) => axios(UrlFactory.receivablePay(), JSON.stringify(encryption(data)), getAccessHeaders())
//日应收，应支
export const  receivablePayDay = (data) => axios(UrlFactory.receivablePayDay(), JSON.stringify(encryption(data)), getAccessHeaders())
//租后费用
export const  afterRent = (data) => axios(UrlFactory.afterRent(), JSON.stringify(encryption(data)), getAccessHeaders())


//-------------------------------------- 统计 财务统计 end -------------------------------------------
//-------------------------------------- 房源相关 start -----------------------------------------
//房源转租状态切换
export const  changeHouseType = (data) => axios(UrlFactory.changeHouseType(), JSON.stringify(encryption(data)), getAccessHeaders())
//报价底价、房源状态修改
export const  updataHousePrice = (data) => axios(UrlFactory.updataHousePrice(), JSON.stringify(encryption(data)), getAccessHeaders())
//房源相关内按钮权限
export const  houseRelevantAuthList = (data) => axios(UrlFactory.houseRelevantAuthList(), JSON.stringify(encryption(data)), getAccessHeaders())
//-------------------------------------- 房源相关 end -------------------------------------------


//-------------------------------------- 统计 客户服务 begin -----------------------------------------
//总投诉量，投诉率
export const  complaintRatio = (data) => axios(UrlFactory.complaintRatio(), JSON.stringify(encryption(data)), getAccessHeaders())
//投诉类型占比
export const  complaintTypeRatio = (data) => axios(UrlFactory.complaintTypeRatio(), JSON.stringify(encryption(data)), getAccessHeaders())
//投诉处理情况
export const  complaintHandle = (data) => axios(UrlFactory.complaintHandle(), JSON.stringify(encryption(data)), getAccessHeaders())


//-------------------------------------- 统计 客户服务 end -------------------------------------------

//-------------------------------------- 统计 业绩 begin -----------------------------------------
//预估业绩、已结业绩
export const  estimateAchievement = (data) => axios(UrlFactory.estimateAchievement(), JSON.stringify(encryption(data)), getAccessHeaders())
//业绩类型统计
export const  achievementType = (data) => axios(UrlFactory.achievementType(), JSON.stringify(encryption(data)), getAccessHeaders())
//单笔情况
export const  single = (data) => axios(UrlFactory.single(), JSON.stringify(encryption(data)), getAccessHeaders())

//-------------------------------------- 统计 业绩 end -------------------------------------------


//-------------------------------------- 统计 装修统计 begin -----------------------------------------
//平均装修时长
export const  decorationTimes = (data) => axios(UrlFactory.decorationTimes(), JSON.stringify(encryption(data)), getAccessHeaders())
//套均装修款
export const  setDecoration = (data) => axios(UrlFactory.setDecoration(), JSON.stringify(encryption(data)), getAccessHeaders())
//验收量
export const  acceptanceCount = (data) => axios(UrlFactory.acceptanceCount(), JSON.stringify(encryption(data)), getAccessHeaders())
//智能门锁占比
export const  intelligentDoorLock = (data) => axios(UrlFactory.intelligentDoorLock(), JSON.stringify(encryption(data)), getAccessHeaders())
//智能电表占比
export const  smartMeter = (data) => axios(UrlFactory.smartMeter(), JSON.stringify(encryption(data)), getAccessHeaders())
//装修满意度
export const  decorationSatisfaction = (data) => axios(UrlFactory.decorationSatisfaction(), JSON.stringify(encryption(data)), getAccessHeaders())
//间均月装修款
export const  monthDecoration = (data) => axios(UrlFactory.monthDecoration(), JSON.stringify(encryption(data)), getAccessHeaders())
//
//-------------------------------------- 统计 装修统计 end -------------------------------------------


//-------------------------------------- 统计 用户画像 start -----------------------------------------
//租房价格区间
export const  rentPriceRange = (data) => axios(UrlFactory.rentPriceRange(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户性别
export const  globaluserSex = (data) => axios(UrlFactory.globaluserSex(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户年龄
export const  globaluserAge = (data) => axios(UrlFactory.globaluserAge(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户数量
export const  globaluserNum = (data) => axios(UrlFactory.globaluserNum(), JSON.stringify(encryption(data)), getAccessHeaders())
//违约用户数量
export const  breachGlobaluserNum = (data) => axios(UrlFactory.breachGlobaluserNum(), JSON.stringify(encryption(data)), getAccessHeaders())
//违约用户年龄
export const  breachGlobaluserAge = (data) => axios(UrlFactory.breachGlobaluserAge(), JSON.stringify(encryption(data)), getAccessHeaders())
//违约用户性别
export const  breachGlobaluserSex = (data) => axios(UrlFactory.breachGlobaluserSex(), JSON.stringify(encryption(data)), getAccessHeaders())
//用户付款方式
export const  userPaymantType = (data) => axios(UrlFactory.userPaymantType(), JSON.stringify(encryption(data)), getAccessHeaders())
//-------------------------------------- 统计 用户画像 end -------------------------------------------

//-------------------------------------- 统计 集寓管理 start -----------------------------------------
//新签、续租、正退、违约
export const  projectInfo = (data) => axios(UrlFactory.projectInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
//集寓项目列表
export const  queryApartmentList = (data) => axios(UrlFactory.queryApartmentList(), JSON.stringify(encryption(data)), getAccessHeaders())
//出租率
export const  queryApartmentInfo = (data) => axios(UrlFactory.queryApartmentInfo(), JSON.stringify(encryption(data)), getAccessHeaders())
//平均房价
export const  avgHousePrice = (data) => axios(UrlFactory.avgHousePrice(), JSON.stringify(encryption(data)), getAccessHeaders())
//续租占比
export const  renewProportion = (data) => axios(UrlFactory.renewProportion(), JSON.stringify(encryption(data)), getAccessHeaders())
//-------------------------------------- 统计 集寓管理 end -------------------------------------------

//-------------------------------------- 统计 租后统计 begin -----------------------------------------
//维修类型占比
export const  maintainGenre = (data) => axios(UrlFactory.maintainGenre(), JSON.stringify(encryption(data)), getAccessHeaders())
//维修完成时长
export const  repairStatistics = (data) => axios(UrlFactory.repairStatistics(), JSON.stringify(encryption(data)), getAccessHeaders())
//保洁响应时长
export const  cleanResponseTime = (data) => axios(UrlFactory.cleanResponseTime(), JSON.stringify(encryption(data)), getAccessHeaders())
//保洁完成时长
export const  cleanStatistics = (data) => axios(UrlFactory.cleanStatistics(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货响应时长
export const  distributionResponseTime = (data) => axios(UrlFactory.distributionResponseTime(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货分类占比
export const  distributionStatistics = (data) => axios(UrlFactory.distributionStatistics(), JSON.stringify(encryption(data)), getAccessHeaders())
//配货完成时长
export const  distributionFinishTime = (data) => axios(UrlFactory.distributionFinishTime(), JSON.stringify(encryption(data)), getAccessHeaders())

//-------------------------------------- 统计 租后统计 end -------------------------------------------

//-------------------------------------- 统计 业务统计 start -----------------------------------------
//房屋状态占比
export const  houseStateProportion = (data) => axios(UrlFactory.houseStateProportion(), JSON.stringify(encryption(data)), getAccessHeaders())
//空置天数占比
export const  vacanyDaysNumProportion = (data) => axios(UrlFactory.vacanyDaysNumProportion(), JSON.stringify(encryption(data)), getAccessHeaders())
//利差
export const  interestMargin = (data) => axios(UrlFactory.interestMargin(), JSON.stringify(encryption(data)), getAccessHeaders())
//出房周期
export const  outHousePeriod = (data) => axios(UrlFactory.outHousePeriod(), JSON.stringify(encryption(data)), getAccessHeaders())
//收房、出房排行榜
export const  homeForeclosure = (data) => axios(UrlFactory.homeForeclosure(), JSON.stringify(encryption(data)), getAccessHeaders())
//签约量
export const  signNum = (data) => axios(UrlFactory.signNum(), JSON.stringify(encryption(data)), getAccessHeaders())

//签约情况（签约量）
export const  signCount = (data) => axios(UrlFactory.signCount(), JSON.stringify(encryption(data)), getAccessHeaders())
//签约情况（违约量）
export const  breakContractNum = (data) => axios(UrlFactory.breakContractNum(), JSON.stringify(encryption(data)), getAccessHeaders())
//签约情况（续签量）
export const  renewalNum = (data) => axios(UrlFactory.renewalNum(), JSON.stringify(encryption(data)), getAccessHeaders())
//租客租期时长占比
export const  rentTimeProportion = (data) => axios(UrlFactory.rentTimeProportion(), JSON.stringify(encryption(data)), getAccessHeaders())
//业务租客付款方式占比
export const  businessUserPaymantType = (data) => axios(UrlFactory.businessUserPaymantType(), JSON.stringify(encryption(data)), getAccessHeaders())
//房间总量
export const  totalHouseNum = (data) => axios(UrlFactory.totalHouseNum(), JSON.stringify(encryption(data)), getAccessHeaders())
//
//-------------------------------------- 统计 业务统计 end -------------------------------------------

//查询一级公司，用于统计公司部门检索
export const  queryFirstLevelCompany = (data) => axios(UrlFactory.queryFirstLevelCompany(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询部门列表，用于统计公司部门检索(带有权限) 王千赫
export const  queryAuthLevelDepartment = (data) => axios(UrlFactory.queryAuthLevelDepartment(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询逾期费用
export const  TotalMoney = (data) => axios(UrlFactory.TotalMoney(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询一级公司，用于统计公司部门检索
export const  findDepartmentByCompanyId = (data) => axios(UrlFactory.findDepartmentByCompanyId(), JSON.stringify(encryption(data)), getAccessHeaders())

//获取用户权限
export const  userAuthList = (data) => axios(UrlFactory.userAuthList(), JSON.stringify(encryption(data)), getAccessHeaders())

//获取清退列表
export const  afterRentClean = (data) => axios(UrlFactory.afterRentClean(), JSON.stringify(encryption(data)), getAccessHeaders())

//添加清退图片
export const  savaUpPhone = (data) => axios(UrlFactory.savaUpPhone(), JSON.stringify(encryption(data)), getAccessHeaders())
//添加清退图片
export const  acceptanceCheck = (data) => axios(UrlFactory.acceptanceCheck(), JSON.stringify(encryption(data)), getAccessHeaders())
//提前付款天数
export const  findAdvanceDay = (data) => axios(UrlFactory.findAdvanceDay(), JSON.stringify(encryption(data)), getAccessHeaders())
//签约预定合同
export const  perContractSign = (data) => axios(UrlFactory.perContractSign(), JSON.stringify(encryption(data)), getAccessHeaders())
//修改租约变更查押金
export const queryRestDepositMoneyBillNew= (data) => axios(UrlFactory.queryRestDepositMoneyBillNew(), JSON.stringify(encryption(data)), getAccessHeaders())
//忘记密码身份证验证
export const certificateCodeVerification= (data) => axios(UrlFactory.certificateCodeVerification(), JSON.stringify(encryption(data)), getAccessHeaders())
//查询推荐人
export const queryRentTenant= (data) => axios(UrlFactory.queryRentTenant(), JSON.stringify(encryption(data)), getAccessHeaders())
//判断上期账单是否已交
export const billContinuity= (data) => axios(UrlFactory.billContinuity(), JSON.stringify(encryption(data)), getAccessHeaders())
//负成本报价
export const updataHouseSpecialOffer= (data) => axios(UrlFactory.updataHouseSpecialOffer(), JSON.stringify(encryption(data)), getAccessHeaders())

export const policyDecisionData= (data) => axios(UrlFactory.policyDecisionData(), JSON.stringify(encryption(data)), getAccessHeaders())

export const queryHouseResourcesOtherExpenses= (data) => axios(UrlFactory.queryHouseResourcesOtherExpenses(), JSON.stringify(encryption(data)), getAccessHeaders())

export const updateVideo= (data) => axios(UrlFactory.updateVideo(), JSON.stringify(encryption(data)), getAccessHeaders())

export const compulsoryDissolution= (data) => axios(UrlFactory.compulsoryDissolution(), JSON.stringify(encryption(data)), getAccessHeaders())
export const intelligentHydropower= (data) => axios(UrlFactory.intelligentHydropower(), JSON.stringify(encryption(data)), getAccessHeaders())

export const querySupplierList= (data) => axios(UrlFactory.querySupplierList(), JSON.stringify(encryption(data)), getAccessHeaders())

export const delVideo= (data) => axios(UrlFactory.delVideo(), JSON.stringify(encryption(data)), getAccessHeaders())

export const updateProgressNew= (data) => axios(UrlFactory.updateProgressNew(), JSON.stringify(encryption(data)), getAccessHeaders())

export const ownercontractCycle= (data) => axios(UrlFactory.ownercontractCycle(), JSON.stringify(encryption(data)), getAccessHeaders())

export const queryBedealtCount= (data) => axios(UrlFactory.queryBedealtCount(), JSON.stringify(encryption(data)), getAccessHeaders())

export const queryBedealtList= (data) => axios(UrlFactory.queryBedealtList(), JSON.stringify(encryption(data)), getAccessHeaders())

export const rentHouseNewTenant= (data) => axios(UrlFactory.rentHouseNewTenant(), JSON.stringify(encryption(data)), getAccessHeaders())

export const setBedealt= (data) => axios(UrlFactory.setBedealt(), JSON.stringify(encryption(data)), getAccessHeaders())

export const browseSetHouseItem= (data) => axios(UrlFactory.browseSetHouseItem(), JSON.stringify(encryption(data)), getAccessHeaders())

export const setHouseItemPhone= (data) => axios(UrlFactory.setHouseItemPhone(), JSON.stringify(encryption(data)), getAccessHeaders())

export const queryMonth= (data) => axios(UrlFactory.queryMonth(), JSON.stringify(encryption(data)), getAccessHeaders())

export const getDepositMoneyType = (data) => axios(UrlFactory.getDepositMoneyType(), JSON.stringify(encryption(data)), getAccessHeaders())