/* --------接口的地址-------------------------- */
//  本地
// let baseUrl = 'http://192.168.10.91:6060'
// manage测试
let baseUrl = 'https://user.api.himihome.com'
// erp正式
// let baseUrl = 'https://erp.user.api.himihome.com'
/* --------左侧菜单是否走权限------------------- */
let authFlag = true
/* ------------参数是否加密----------------------------- */
let encodeFlag = false
/* ------------授权服务器的地址----------------------------- */
// 本地
// let authUrl = 'http://192.168.10.91:5555'
// manage测试
let authUrl = 'https://auth.himihome.com'
// erp正式
// let authUrl = 'https://erp.auth.himihome.com'
export {
    baseUrl,
    authFlag,
    encodeFlag,
    authUrl

}
